import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Container } from "../components/Layouts";
import '../styles/Cashcard.css'

class Cashcard extends Component {
    constructor() {
        super();
        this.handleToggle = this.handleToggle.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    componentWillMount() {
        window.addEventListener("resize", e => this.handleResize(e));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", e => this.handleResize(e));
    }

    handleToggle(e) {
                
        let node = e.target.parentNode.nextSibling;
        let isOpen = node.classList.contains("cashcard--mobile--dropdown--open");
        let isClosed = node.classList.contains("cashcard--mobile--dropdown--closed");        
        
        if (isOpen) {
            node.classList.remove("cashcard--mobile--dropdown--open");
            node.classList.add("cashcard--mobile--dropdown--closed");
            return;
        } else if (isClosed) {
            node.classList.remove("cashcard--mobile--dropdown--closed");
            node.classList.add("cashcard--mobile--dropdown--open");
            return;
        } else {
            node.classList.add("cashcard--mobile--dropdown--open");
            return;
        }
    }

    handleResize(e) {        
        let width = window.innerWidth;
        if (width >= 680 && window.location.pathname === "/cashcard") {
            let node = document.getElementById("cashcard--dropdown");
            node.classList.remove("cashcard--mobile--dropdown--closed");
            node.classList.remove("cashcard--mobile--dropdown--open")
        }
    }

    render() {

        return (
            <Fragment>
                <Container>
                    <header className="cashcard--header" id="cashcard">
                        <ul className="cashcard--header--brand">
                            <li><img className="cashcard--header--brand--spotto" src="/assets/images/Spotto.png" alt="Spotto"/></li>
                        </ul>
                        <ul className="cashcard--header--links">
                            <li><NavLink to="/">Home</NavLink></li>
                            <li><Link to="/#contact">Contact</Link></li>
                            <li><NavLink activeClassName="cashcard--nav--active" to="/cashcard">Cash Card</NavLink></li>
                            <li><Link to="/#refer">Refer a Friend</Link></li>
                            <li><NavLink to="/howtouse">How to use your spotto</NavLink></li>
                        </ul>
                        <ul className="cashcard--navlinks--mobile">
                            <div onClick={(e) => this.handleToggle(e)} id="hamburger"><img src="/assets/images/menu.svg" alt="Hamburger Menu"/></div>
                            <div id="cashcard--dropdown" className="cashcard--mobile--dropdown">
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink activeClassName="cashcard--nav--active" to="/cashcard">Cash Card</NavLink></li>
                                <li><Link to="/#refer">Refer a Friend</Link></li>
                                <li><Link to="/#contact">Contact</Link></li>
                                <li><NavLink to="/howtouse">How to use your spotto</NavLink></li>
                            </div>
                        </ul>
                    </header>

                    <section className="cashcard--refer">

                            <div className="cashcard--refer--heading">
                                <span className="home--sideline"></span>
                                <h1>Check your card balance and transaction history</h1>                                
                            </div>
                    </section>

                    <section className="cashcard--hero">
                        <div className="cashcard--left">
                                <ol>
                                    <li>Click <b>Continue to Sign In</b> to go to the card’s online portal</li>
                                    <li>Enter the 16 digit card number printed on the front of your card</li>
                                    <li>Select <b>Card Number and PIN (4 Digit Passcode)</b> to sign in</li>
                                    <li>Enter the email address associated with your Spotto account</li>
                                    <div className="cashcard--link--wrapper">
                                        <a className="cashcard--link" href="https://sam.emerchants.com.au/CabchargeSpottoCashCard">Continue to Sign In</a>
                                    </div>
                                    <div className="cashcard-info">
                                        <a href="https://spotto.com.au/assets/spottocashcardpds.pdf">Product Disclosure Statement</a>
                                    </div>
                                    <div className="guide-info">
                                        <a href="https://spotto.com.au/assets/spottocashcardfsg.pdf">Financial Services Guide</a>
                                    </div>
                                </ol>       
                        </div>
                            
                        <div className="cashcard--right">
                            <div className="spotto--cash-img">
                                    <img src="/assets/images/spottoCash.png" alt="Spotto cash"/>
                            </div>
                            <div className="cashcard--signin-img">        
                                    <img src="/assets/images/CashCardSignin.png" alt="Cash card signin"/>
                            </div>
                        </div>
                        
                    </section>

                    {/* Section footer */}
                    <section className="home--footer">
                        <div>
                            <p>This material is general information only and does not take into account of your objectives, financial situation or needs. Terms and conditions and fees and charges will apply to use of the Card. We recommend you consider the <a href="https://spotto.com.au/assets/spottocashcardpds.pdf">Product Disclosure Statement (PDS)</a> relating to this product before making any decision. This product is available only to Australian residents and to use the Spotto Cash Card, it must be activated. The Spotto Cash Card is a prepaid, reloadable Mastercard issued by EML Payment Solutions Ltd ABN 30 131 436 532 AFSL 404131. Cabcharge Payments Pty Ltd ABN 22 615 032 427 is involved in the promotion and distribution of the Card and is a Corporate Authorised Representative of EML Payment Solutions Limited. Mastercard and the Mastercard brand mark are registered trademarks, and the circles design is a trademark of Mastercard International Incorporated. The Target Market Determination for this product can be found <a href="/assets/Spotto_EML_TMD.pdf">here</a>.</p>
                        </div>
                    </section>               

                </Container>

            </Fragment>
        );
    }
}

export default Cashcard;