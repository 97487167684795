module.exports = {
  emailRegisterSubject: process.env.REACT_APP_SPOTTO_REGISTER_EMAIL_TITLE || 'Spotto Sign Up',
  emailReferSubject: process.env.REACT_APP_SPOTTO_REFER_EMAIL_TITLE || 'Spotto Referral',
  emailReplyTo: process.env.REACT_APP_SPOTTO_REPLY_ADDR || 'noreply@spotto.com.au',
  fromEmailAddr: process.env.REACT_APP_SPOTTO_SENDER_ADDR || 'No-Reply<noreply@spotto.com.au>',
  rootAddress: process.env.REACT_APP_WEBSITE_ROOT,
  apiServer: process.env.REACT_APP_API_SERVER,
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY, 
  referNotificationEmailAddress: process.env.REACT_APP_REFER_NOTIFICATION_EMAIL_DEST,
  signupNotificationEmailAddress: process.env.REACT_APP_SIGNUP_NOTIFICATION_EMAIL_DEST,
  dataverseApi: process.env.REACT_APP_DATAVERSE_API,
  ocpApimKey: process.env.REACT_APP_OCP_APIM_KEY,
};

