import axios from "axios";
import moment from "moment";
import config from "../config/config";

function validate(form) { 
    
    let errors = {};

    const { referrerFirstName, referreeFirstName, referrerLastName, referreeLastName} = form.elements;
    const { referrerPhoneNumber, referreePhoneNumber } = form.elements;
    const { referrerEmail, referreeEmail } = form.elements;
    
    // If a name is empty it is invalid
    errors.referrerFirstName = referrerFirstName.value.length > 0 ? true : false;
    errors.referrerLastName = referrerLastName.value.length > 0 ? true : false;
    errors.referreeFirstName = referreeFirstName.value.length > 0 ? true : false;
    errors.referreeLastName = referreeLastName.value.length > 0 ? true : false;

    // If a telephone number is valid
    let phoneRegEx = /^\d+$/;

    if (referrerPhoneNumber.value.length >= 8 && phoneRegEx.test(referrerPhoneNumber.value)) {
        errors.referrerPhoneNumber = true;     
    } else {
        errors.referrerPhoneNumber = false;
    }

    if (referreePhoneNumber.value.length >= 8 && phoneRegEx.test(referreePhoneNumber.value)) {
        errors.referreePhoneNumber = true;     
    } else {
        errors.referreePhoneNumber = false;
    }

    // If a email does not have the correct email format it is invalid
    let emailRegEx = /\S+@\S+\.\S+/;
    errors.referrerEmail = emailRegEx.test(referrerEmail.value);
    errors.referreeEmail = emailRegEx.test(referreeEmail.value);

    // Configure the values for the email service syntax
    let values = {
        firstName: referrerFirstName.value, 
        referee_firstName: referreeFirstName.value,
        lastName: referrerLastName.value,
        referee_lastName: referreeLastName.value,
        phone: referrerPhoneNumber.value,
        referee_phone: referreePhoneNumber.value,
        email: referrerEmail.value,
        referee_email: referreeEmail.value
    }

    return {
        errors,
        values
    }
}

async function submitForm(values){
    
    let emailset=[];
    let mailSetup = Object.assign({}, values);
  
    mailSetup.siteAddress = config.rootAddress;
    mailSetup.replyTo = config.emailReplyTo;
    mailSetup.from = config.fromEmailAddr;
    mailSetup.subject = config.emailReferSubject;
    mailSetup.to = config.referNotificationEmailAddress;
    mailSetup.template = 'refer-notification-email';
    mailSetup.referral_date = moment().format('DD/MM/YYYY hh:mm a');
    emailset.push(mailSetup);
  
    var refermailSetup = Object.assign({}, values);
    refermailSetup.siteAddress = config.rootAddress;
    refermailSetup.replyTo = config.emailReplyTo;
    refermailSetup.from = config.fromEmailAddr;
    refermailSetup.subject = config.emailReferSubject;
    refermailSetup.to = values.referee_email;
    refermailSetup.template = 'refer-email';
    refermailSetup.referral_date = moment().format('DD/MM/YYYY hh:mm a');
    emailset.push(refermailSetup);

    let result = {};
    
    await axios.post(config.apiServer, emailset,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': true,
            },
            validateStatus: function (status) {
                return status < 500; // Reject only if the status code is greater than or equal to 500
            }
        })
        .then(function (response) {
            if (response && response.status && response.status === 200) {
                result.success = true;
                result.msg = "Thank you. Please feel free to refer another friend."
            } else {                
                result.success = false;
                result.msg = "Oops, there has been a problem. Please contact us on 1300 776 886."
            }
        })
        .catch(function (error) {
            result.success = false;
            result.msg = "Oops, there has been a problem. Please contact us on 1300 776 886."
        });
    
    return result
}

function sendFormToDataverse(values)
{
    const dataverseData = {
        referred_firstName: values.referee_firstName,
        referred_lastName: values.referee_lastName,
        referred_phone: values.referee_phone,
        referred_email: values.referee_email,

        referrer_phone: values.phone,
        referrer_firstName: values.firstName,
        referrer_lastName:values.lastName,
        referrer_email: values.email,
    }
    
    return axios.post(config.dataverseApi, dataverseData, {
        headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": config.ocpApimKey,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true,
        },
        validateStatus: function (status) {
            return status < 500; // Reject only if the status code is greater than or equal to 500
        }
    });
}

export { validate, submitForm , sendFormToDataverse}