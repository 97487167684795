import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { Container, Inner } from "../components/Layouts";
import ReCAPTCHA from "react-google-recaptcha";
import  { validate, submitForm, sendFormToDataverse } from "../helpers/helpers";
import "../styles/Home.css";

class Home extends Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.checkForEmpty = this.checkForEmpty.bind(this);
        this.captchaUpdate = this.captchaUpdate.bind(this);

        this.state = {
            submitting: false,
            captcha: false,
            cVal: null
        }
    }

    componentWillMount() {
        window.addEventListener("resize", e => this.handleResize(e));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", e => this.handleResize(e));
    }

    captchaUpdate(e) {
        this.setState({ captcha: true, cVal: e })
    }

    checkForEmpty(e) {
        if (e.target.value.length === 0) {
            let node = e.target.nextSibling;
            if (node.classList.contains("refer--error--hidden")) {
                node.classList.remove("refer--error--hidden");
                return
            } 
        }
    }

    async handleSubmit(e) {

        e.preventDefault();
        let form = document.forms.refer;
        let feedbackNode = document.getElementById("refer--feedback");
        feedbackNode.classList.remove("show--feedback");
        feedbackNode.classList.remove("success");

        if (!this.state.captcha) {
            document.getElementById("captcha--error").classList.remove("refer--error--hidden")
        }
        
        let { errors, values } = validate(form);        

        // Take all false errors and remove error class
        for (let key in errors) {
            if (!errors[key]) {
                document.getElementById(`${key}Error`).classList.remove("refer--error--hidden");
            } else {
                document.getElementById(`${key}Error`).classList.add("refer--error--hidden");
            }
        }
        
        let allFieldsErrorFree = 8;

        for (let key in errors) {
            if (errors[key]) {
                allFieldsErrorFree--;
            }
        }

        if (allFieldsErrorFree === 0 && this.state.captcha) {
            
            this.setState({ submitting: true });
            values.captcharesponse = this.state.cVal;
            
            try {
                let result = await submitForm(values);
                
                if (result.success) {
                    this.setState({ submitting: false });
                    feedbackNode.classList.add("show--feedback");
                    feedbackNode.classList.add("success");
                    feedbackNode.firstChild.textContent = result.msg;
                    form.reset();
                    window.grecaptcha.reset();
                } else {
                    this.setState({ submitting: false });
                    feedbackNode.classList.add("show--feedback");
                    feedbackNode.firstChild.textContent = result.msg;
                    form.reset();
                    window.grecaptcha.reset();
                }
            } catch (error) {
                this.setState({ submitting: false });
                feedbackNode.classList.add("show--feedback");
                feedbackNode.firstChild.textContent = "Oops, there has been a problem. Please contact us on 1300 776 886.";
                form.reset();
                window.grecaptcha.reset();
            }
            
            try {
                let resultDataverse = await sendFormToDataverse(values);
                console.log('dataverse response code ' + resultDataverse.status);
            } catch (err) {
                console.log('dataverse response error: ' + err);
            }
        }
    }

    handleToggle(e) {

        let node = e.target.parentNode.nextSibling;
        let isOpen = node.classList.contains("mobile--dropdown--open");
        let isClosed = node.classList.contains("mobile--dropdown--closed");        
        
        if (isOpen) {
            node.classList.remove("mobile--dropdown--open");
            node.classList.add("mobile--dropdown--closed");
            return;
        } else if (isClosed) {
            node.classList.remove("mobile--dropdown--closed");
            node.classList.add("mobile--dropdown--open");
            return;
        } else {
            node.classList.add("mobile--dropdown--open");
            return;
        }
    }

    handleResize(e) {        
        let width = window.innerWidth;
        if (width >= 680 && window.location.pathname === "/") {
            // Remove any classes still on mobile nav
            let node = document.getElementById("dropdown");
            node.classList.remove("mobile--dropdown--closed");
            node.classList.remove("mobile--dropdown--open")
        }
    }

    render() {
        return (
            <Fragment>
                {/* Hero Home */}
                <Container >
                    <div className="home">
                        {/* Section Header */}
                        <header className="home--header">
                            <Inner>
                                <div className="home--navmenu">
                                    <ul className="home--logo">
                                        <li><NavLink to="/"><img src="/assets/images/spotto-logo@4x.png" alt="Spotto"/></NavLink></li>
                                    </ul>
                                    <ul className="home--navlinks">
                                        <li><NavLink to="/cashcard">Cash Card</NavLink></li>
                                        <li><a href="#refer">Refer a Friend</a></li>
                                        <li><a href="#contact">Contact</a></li>
                                        <li><NavLink to="/howtouse">How to use your spotto</NavLink></li>
                                    </ul>
                                    <ul className="home--navlinks--mobile">
                                        <div onClick={(e) => this.handleToggle(e)} id="hamburger"><img src="/assets/images/menu.svg" alt="Hamburger Menu"/></div>
                                        <div id="dropdown" className="mobile--dropdown">
                                            <li><NavLink to="/cashcard">Cash Card</NavLink></li>
                                            <li><a href="#refer">Refer a Friend</a></li>
                                            <li><a href="#contact">Contact</a></li>
                                            <li><NavLink to="/howtouse">How to use your spotto</NavLink></li>
                                        </div>
                                    </ul>
                                </div>
                            </Inner>
                        </header>

                        {/* Section Home Hero */}
                        <section className="home--hero">
                            <Inner>
                                <div className="home--hero--inner">
                                    <div className="home--hero--left">
                                        <div className="home--hero--left--holder">
                                            <div className="home--hero--left--heading">
                                            	<h1>Fast.</h1>
                                            	<h1>Easy.</h1>
                                            	<h1>Flexible.</h1>
                                            </div>
                                            <div className="home--hero--left--terminal">
                                                <img src="/assets/images/Terminal_new.png" alt="Terminal Mobile"/>
                                            </div>
                                        </div>
                                        <h2>Take control on the spot</h2>
                                        <p>Spotto is a mobile payment terminal for taxis. Light, contactless, and designed with taxi drivers in mind, Spotto puts you in control of your payments.</p>
                                        <div className="home--learnmore">
                                            <a href="#about">Learn More</a>
                                        </div>
                                        <h2>Track your payments with ease</h2>
                                        <p>Now view your transactions and payments on the Spotto App!</p>
                                        <ul className="home--storelinks">
                                            <li>
                                                <a href="https://itunes.apple.com/au/app/spotto-for-taxi-drivers/id1215124450?mt=8,">
                                                    <img src="/assets/images/app_store_icon.png" alt="App Store"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://play.google.com/store/apps/details?id=au.com.spotto.driverapp&hl=en">
                                                    <img src="/assets/images/google_play_icon.png" alt="Play Store"/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="home--hero--right">
                                        <img className="home--hero--right--terminal" src="/assets/images/Terminal_new.png" alt="Terminal"/>
                                    </div>
                                </div>
                            </Inner>
                        </section>

                        {/* Section Home How */}
                        <section className="home--how" id="about">

                            <div className="home--how--heading">
                                <span className="home--sideline"></span>
                                <h1>How Spotto makes life easy:</h1>
                            </div>

                            <div className="home--features">
                                <div className="home--feature--item">
                                    <div className="holder-img"><img src="/assets/images/earn_extra.svg" alt="Spotto Extra 1%"/></div>
                                    <div className="holder-h1"><h1>We pay you an extra 1%</h1></div> 
                                    <div className="holder-h2"><h2>Boost your earnings with every payment</h2></div>
                                    <p>Every time a customer pays with Spotto, you'll earn an extra 1% on top of the fare. It soon adds up.</p>
                                </div>

                                <div className="home--feature--item">
                                    <div className="holder-img"><img src="/assets/images/Time-01.svg" alt="Spotto Paid Sooner"/></div>
                                    <div className="holder-h1"><h1>Get paid sooner</h1></div>
                                    <div className="holder-h2"><h2>Fares settled every day – 7 days a week*</h2></div>
                                    <p>Just nominate a bank account and you'll receive daily payments (Mon-Fri<sup>*</sup>), keeping you in control.</p>
                                </div>

                                <div className="home--feature--item">
                                    <div className="holder-img"><img src="/assets/images/Bonus-01.svg" alt="Spotto Bonus"/></div>
                                    <div className="holder-h1"><h1>Generous bonus programs</h1></div>
                                    <div className="holder-h2"><h2>$50 for every referral & loyalty schemes</h2></div>
                                    <p>Recommend Spotto to another driver and get a $50 bonus. You will also receive access to promotions and loyalty rewards for using Spotto.<sup>**</sup></p>
                                </div>
                            </div>
                        </section>

                        {/* Section Home  Here*/}
                        <section className="home--here">
                            <div className="home--here--left">
                                <div className="home--here--left--terminal">
                                    <div className="terminal--overlay--large">
                                        <div className="terminal--overlay">
                                            <h1>Take control</h1>
                                            <h1>With Spotto</h1>
                                        </div>
                                        <img src="assets/images/Terminal_2x.png" alt="Take Control with Spotto" />
                                    </div>
                                    <div className="terminal--overlay--small">
                                        <div className="terminal--overlay--small--text">
                                        	<h1>Take Control</h1>
                                        	<h1>With Spotto</h1>
                                        </div>
                                        <img src="assets/images/Terminal_2x.png" alt="Take Control with Spotto" />
                                    </div>
                                </div>
                                
                            </div>
                            <div className="home--here--right">
                                <h1>Here's How:</h1>
                                <div className="how--holder">
                                    <img src="/assets/images/QuickSignUp-01.svg" alt="Quick Sign Up"/>
                                    <p>Quick Sign Up</p>
                                </div>

                                <div className="how--holder">
                                    <img src="/assets/images/CreditCard-01.svg" alt="Major Cards Accepted"/>
                                    <p>Major cards accepted</p>
                                </div>

                                <div className="how--holder">
                                    <img src="/assets/images/TapandGo-01.svg" alt="Tap and Go"/>
                                    <p>Fast, contactless payments - customers just tap and go</p>
                                </div>
                            </div>
                        </section>
                        {/* Section to put horizontal line */}
                        <section className="section--horizontal-line" id="line">
                                <div className="horizontal-line">
                                        <hr></hr>
                                </div>
                        </section>
                         {/* Section Spotto Cash Card */}
                         <section className="home--cashcard" id="cashcard">
                            <div className="home--cashcard--left">
                                    <span className="home--sideline"></span>
                                    <div className="home--cashcard--left--meta"> 
                                        <h1>Spotto Cash Card</h1> 
                                        <h2>THE NEWEST AND MOST CONVENIENT WAY TO GET PAID!</h2> 
                                        <ul>
                                            <li>Shop online, in-store, or withdraw cash at any ATM<sup>*</sup></li>
                                            <li>Access your money sooner, no more waiting for the bank to settle your funds</li>
                                            <li>Check your Cash Card balance and transaction history online</li>
                                        </ul>
                                        
                                         
                                         <div className="cashcard-learn-more--resp">
                                            <p><Link className="cashcard-learn-more--resp" to="/cashcard#cashcard">Already have a card?</Link></p>
                                        </div>
                                                              
                                    </div>
                                
                            </div>
                            <div className="cashcard-learn-more">
                                <p><Link className="cashcard-learn-more" to="/cashcard#cashcard">Already have a card?</Link></p>
                            </div>
                            
                            <div className="home--cashcard--right">
                                <svg viewBox="0 0 100 100" preserveAspectRatio="none" className="home--cashcard--right--background">
                                    <polygon points="100,0 0,100 100,100" style={{ fill: '#fff'}} />
                                </svg>        
                                      {/* <svg viewBox="0 0 120 130" className="home--cashcard--right--background">
                                    <polygon points="120,0 0,130 120,130" style={{ fill: '#fff'}} />

                                </svg>        */}
                                <div className="spotto--cashcard-img">
                                    <img src="/assets/images/spotto-card.png" alt="Spotto card"/>
                                </div>
                                <div className="spotto--cashcard--information">
                                    <p><sup>*</sup>Free at most major bank ATMs. ATM Owner fees may apply and will be disclosed at the time of the transaction.</p>
                               </div>
                            </div>
                            
                        </section>

                        {/* Section to put horizontal line */}
                        <section className="section--horizontal-line" id="line">
                                <div className="horizontal-line">
                                        <hr></hr>
                                </div>
                        </section>

                        {/* Section Refer a Friend */}
                        <section className="home--refer" id="refer">

                            <div className="home--refer--heading">
                                <span className="home--sideline"></span>
                                <h1>Refer a friend and earn $50</h1>                                
                            </div>
                            <div className="home--refer--subheading">
                                <p>We think you'll love using Spotto and want to tell your friends about it. For every fellow taxi driver you refer, we'll give you $50<sup>**</sup>.</p>
                                <p>Simply fill in your friend's details below (with their consent) and we'll send them an email about Spotto.</p>
                            </div>

                            <form name="refer">
                                <fieldset>
                                    <div className="home--refer--referrer">
                                        <h2>Your Details</h2>
                                        <div className="refer--wrapper">
                                            <div className="refer--column">
                                                <div className="refer--input">
                                                    <label htmlFor="referrerFirstName">First Name</label>
                                                    <input type="text" name="referrerFirstName" id="referrerFirstName" onBlur={(e) => this.checkForEmpty(e)}/>
                                                    <span className="refer--error refer--error--hidden" id="referrerFirstNameError">Please provide a first name</span>
                                                </div>
                                                <div className="refer--input">
                                                    <label htmlFor="referrerLastName">Last Name</label>
                                                    <input type="text" name="referrerLastName" id="referrerLastName" onBlur={(e) => this.checkForEmpty(e)}/>
                                                    <span className="refer--error refer--error--hidden" id="referrerLastNameError">Please provide a last name</span>
                                                </div>
                                            </div>
                                            <div className="refer--column">
                                                <div className="refer--input">
                                                    <label htmlFor="referrerPhoneNumber">Phone Number</label>
                                                    <input type="number" name="referrerPhoneNumber" id="referrerPhoneNumber" onBlur={(e) => this.checkForEmpty(e)}/>
                                                    <span className="refer--error refer--error--hidden" id="referrerPhoneNumberError">Please provide a phone number</span>
                                                </div>
                                                <div className="refer--input">
                                                    <label htmlFor="referrerEmail">Email</label>
                                                    <input type="text" name="referrerEmail" id="referrerEmail" onBlur={(e) => this.checkForEmpty(e)}/>
                                                    <span className="refer--error refer--error--hidden" id="referrerEmailError">Please provide a valid email</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="home--refer--referree">
                                        <h2>Your friend's name</h2>
                                        <div className="refer--wrapper">
                                            <div className="refer--column">
                                                <div className="refer--input">
                                                    <label htmlFor="referreeFirstName">First Name</label>
                                                    <input type="text" name="referreeFirstName" id="referreeFirstName" onBlur={(e) => this.checkForEmpty(e)}/>
                                                    <span className="refer--error refer--error--hidden" id="referreeFirstNameError">Please provide a first name</span>
                                                </div>
                                                <div className="refer--input">
                                                    <label htmlFor="referreeLastName">Last Name</label>
                                                    <input type="text" name="referreeLastName" id="referreeLastName" onBlur={(e) => this.checkForEmpty(e)}/>
                                                    <span className="refer--error refer--error--hidden" id="referreeLastNameError">Please provide a last name</span>
                                                </div>
                                            </div>
                                            <div className="refer--column">
                                                <div className="refer--input">
                                                    <label htmlFor="referreePhoneNumber">Phone Number</label>
                                                    <input type="number" name="referreePhoneNumber" id="referreePhoneNumber" onBlur={(e) => this.checkForEmpty(e)}/>
                                                    <span className="refer--error refer--error--hidden" id="referreePhoneNumberError">Please provide a phone number</span>
                                                </div>
                                                <div className="refer--input">
                                                    <label htmlFor="referreeEmail">Email</label>
                                                    <input type="text" name="referreeEmail" id="referreeEmail" onBlur={(e) => this.checkForEmpty(e)}/>
                                                    <span className="refer--error refer--error--hidden" id="referreeEmailError">Please provide a valid email</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="refer--feedback" id="refer--feedback">
                                        <h3>Content Below</h3>
                                    </div>

                                    <div className="refer--meta">
                                        <div className="refer--form--captcha">
                                            <ReCAPTCHA
                                                sitekey={"6LfsNg8UAAAAACQW3xGMuddh9r0pYdmNDDOMaMvg"}
                                                id="home--captcha"
                                                onChange={(e) => this.captchaUpdate(e)}
                                            />
                                            <span id="captcha--error" className="refer--error refer--error--hidden">Please confirm captcha</span>
                                        </div>
                                        <div className={`refer--form--submit ${this.state.submitting ? "submitting" : ""}`}>
                                            <input type="submit" value={this.state.submitting ? "Submitting" : "Submit"} onClick={(e) => this.handleSubmit(e)}/>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>

                        </section>

                        {/* Contact is Responsive */}
                        <section className="home--contact" id="contact">
                            <div className="home--contact--wrap">
                                <span className="home--sideline"></span>
                                <h1>Contact us</h1>
                            </div>

                            <div className="home--contact--inner">
                                <div className="home--contact--type">
                                    <div className="home--contact--phone">
                                        <h2>Phone</h2>
                                        <a href="tel:1300776886">1300 776 886</a>
                                    </div>
                                    <div className="home--contact--email">
                                        <h2>Email</h2>
                                        <a href="mailto:support@spotto.com.au">support@spotto.com.au</a>
                                    </div>
                                </div>

                                <h2 className="home--contact--signup">To signup and get your terminal, head to:</h2>
                                <div className="home--contact--location">
                                    <div className="home--location--left">
                                        <div className="home--contact--location--item">
                                            <h2>NSW</h2>
                                            <div>
                                                <p>13Cabs</p>
                                                <p>9-13 O'Riordan St,</p>
                                                <p>Alexandria</p>
                                                <p>NSW 2015</p><br />
                                                <p>Open 9:00am-4:00pm (Mon-Fri)</p>
                                            </div>
                                            <div>
                                                <p>Taxi Tech</p>
                                                <p>5/8 Channel Road (Enter</p>
                                                <p>from Metal Pit Drive)</p>
                                                <p>Mayfield West</p>
                                                <p>NSW 2304</p><br />
                                                <p>Open 9:00am-4:00pm (Mon-Fri)</p>
                                            </div>
                                            <h2>QLD</h2>
                                            <div>
                                                <p>13Cabs</p>
                                                <p>7 Albion Street</p>
                                                <p>Woolloongabba</p>
                                                <p>QLD 4102</p><br />
                                                <p>Open 9:00am-4:00pm (Mon-Fri)</p>
                                            </div>
                                            <div>
                                                <p>13Cabs</p>
                                                <p>2552 Gold Coast Highway</p>
                                                <p>Mermaid Beach</p>
                                                <p>QLD 4218</p><br />
                                                <p>Open 9:00am-4:00pm (Mon-Fri)</p>
                                            </div>
                                            <h2>WA</h2>
                                            <div>
                                                <p>Complete Cab Care</p>
                                                <p>206 Star Street</p>
                                                <p>Welshpool</p>
                                                <p>WA 6106</p><br />
                                                <p>Open 9:00am-4:00pm (Mon-Fri)</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="home--location--right">
                                        <div className="home--contact--location--item">
                                            <h2>VIC</h2>
                                            <div>
                                                <p>TaxiTech</p>
                                                <p>Level 1, 199 Arden St</p>
                                                <p>North Melbourne</p>
                                                <p>VIC 3051</p><br />
                                                <p>Open 9:00am-4:00pm (Mon-Fri)</p>
                                            </div>
                                            <div>
                                                <p>TaxiTech</p>
                                                <p>41 Oxford Street</p>
                                                <p>Oakleigh</p>
                                                <p>VIC 3166</p><br />
                                                <p>Open 8:00am-12:00pm (Mon-Fri)</p>
                                            </div>
                                            <h2>SA</h2>
                                            <div>
                                                <p>13 Cabs</p>
                                                <p>3 Frank Collopy Court</p>
                                                <p>Adelaide Airport</p>
                                                <p>SA 5950</p><br />
                                                <p>Open 9:00am-4:00pm (Mon-Fri)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Section Contact Us */}
                        <section className="home--footer">
                            <div>
                                <p>This material is general information only and does not take into account of your objectives, financial situation or needs. Terms and conditions and fees and charges will apply to use of the Card. We recommend you consider the <a href="https://spotto.com.au/assets/spottocashcardpds.pdf">Product Disclosure Statement (PDS)</a> relating to this product before making any decision. This product is available only to Australian residents and to use the Spotto Cash Card, it must be activated. The Spotto Cash Card is a prepaid, reloadable Mastercard issued by EML Payment Solutions Ltd ABN 30 131 436 532 AFSL 404131. Cabcharge Payments Pty Ltd ABN 22 615 032 427 is involved in the promotion and distribution of the Card and is a Corporate Authorised Representative of EML Payment Solutions Limited. Mastercard and the Mastercard brand mark are registered trademarks, and the circles design is a trademark of Mastercard International Incorporated.</p>
                                <p><sup>*</sup> Fares transferred every day for NPP enabled accounts.</p>
                                <p><sup>**</sup> Recommend Spotto to another taxi driver and get a one-time $50 (incl. GST) referral bonus, once their Spotto payments reach $500 within 28 days.</p>
                            </div>
                            <a href="https://spotto.com.au/assets/Spotto_Privacy_Policy.pdf">Spotto Privacy Policy</a>
                        </section>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default Home;