import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Cashcard from './pages/Cashcard'
import Howtouse from './pages/Howtouse'

class App extends Component {
  
  render() {
    const App = () => (
      <Fragment>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/cashcard" component={Cashcard} />
          <Route exact path="/howtouse" component={Howtouse} />
          <Route component={Home} />
        </Switch>
      </Fragment>
    )

    return (
      <Switch>
        <App />
      </Switch>
    )
  }
}

export default App;
