import React from 'react';

// An outer container
const Container = props => {
    return (
        <div className="container">
            {props.children}
        </div>
    )
}

// An inner container that holds content
const Inner = props => {
    return (
        <div className="inner">
            {props.children}
        </div>
    )
}

export {
    Container,
    Inner,
}