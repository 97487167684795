/* This file is added by Thanh */
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Container } from "../components/Layouts";
import ReactPlayer from 'react-player/vimeo';
import '../styles/Howtouse.css'

function Player(props) {
    return (
    	<div className='player--wrapper'>
            <ReactPlayer
                url={props.url}
                className='react--player'
                width="100%"
                height="100%"
                controls={true}
            />
      </div>
    )
}

class Howtouse extends Component {
    constructor() {
        super();
        this.handleToggle = this.handleToggle.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    componentWillMount() {
        window.addEventListener("resize", e => this.handleResize(e));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", e => this.handleResize(e));
    }

    handleToggle(e) {
                
        let node = e.target.parentNode.nextSibling;
        let isOpen = node.classList.contains("howtouse--mobile--dropdown--open");
        let isClosed = node.classList.contains("howtouse--mobile--dropdown--closed");        
        
        if (isOpen) {
            node.classList.remove("howtouse--mobile--dropdown--open");
            node.classList.add("howtouse--mobile--dropdown--closed");
            return;
        } else if (isClosed) {
            node.classList.remove("howtouse--mobile--dropdown--closed");
            node.classList.add("howtouse--mobile--dropdown--open");
            return;
        } else {
            node.classList.add("howtouse--mobile--dropdown--open");
            return;
        }
    }

    handleResize(e) {        
        let width = window.innerWidth;
        if (width >= 680 && window.location.pathname === "/howtouse") {
            let node = document.getElementById("howtouse--dropdown");
            node.classList.remove("howtouse--mobile--dropdown--closed");
            node.classList.remove("howtouse--mobile--dropdown--open")
        }
    }

    render() {

        return (
            <Fragment>
                <Container>
                    <header className="howtouse--header" id="howtouse">
                        <ul className="howtouse--header--brand">
                            <li><img className="howtouse--header--brand--spotto" src="/assets/images/Spotto.png" alt="Spotto"/></li>
                        </ul>
                        <ul className="howtouse--header--links">
                            <li><NavLink to="/">Home</NavLink></li>
                            <li><Link to="/#contact">Contact</Link></li>
                            <li><NavLink to="/cashcard">Cash card</NavLink></li>
                            <li><Link to="/#refer">Refer a Friend</Link></li>
                            <li><NavLink to="/howtouse">How to use your spotto</NavLink></li>
                        </ul>
                        <ul className="howtouse--navlinks--mobile">
                            <div onClick={(e) => this.handleToggle(e)} id="hamburger"><img src="/assets/images/menu.svg" alt="Hamburger Menu"/></div>
                            <div id="howtouse--dropdown" className="howtouse--mobile--dropdown">  
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><Link to="/#contact">Contact</Link></li>
                                <li><NavLink to="/cashcard">Cash card</NavLink></li>
                                <li><Link to="/#refer">Refer a Friend</Link></li>
                                <li><NavLink to="/howtouse">How to use your spotto</NavLink></li>
                            </div>
                        </ul>
                    </header>

                    <section>
                        <div className="howtouse--left">
                            <ol>
                                <li>How to switch on your Spotto<Player url="https://vimeo.com/468008780"/></li>
                                <li>How to start your shift on Spotto<Player url="https://vimeo.com/468009416"/></li>
                                <li>How to process a payment on Spotto <Player url="https://player.vimeo.com/video/468028633"/></li>
                                <li>How to change the paper roll<Player url="https://vimeo.com/468028738"/></li>
                                <li>How to end your shift on Spotto<Player url="https://vimeo.com/468028814"/></li>
                                <li>How to switch off your Spotto<Player url="https://vimeo.com/468028957" /> </li>
                            </ol>
                        </div>
                        
                    </section>
                </Container>

            </Fragment>
        );
    }
}

export default Howtouse;